import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import graphic from '../images/filmmaker.jpg'
import demo1 from '../images/demo-1.png'
import demo2 from '../images/demo-2.png'
import styled from 'styled-components'

const DemoButton = styled.a`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="pb-20">
      <div className="container mx-auto max-w-screen-lg px-5 text-center">
        <h1 className="text-3xl md:text-5xl leading-tight font-heading font-light text-gray-800 mb-2 leadning-snug">Custom microsites for Indie Films</h1>
        <p className="text-xl max-w-screen-md mx-auto leading-7 text-gray-700">We build fast, mobile friendly websites for you, because you're too busy to learn how to use Squarespace.</p>
      </div>
    </section>
    <section className="bg-blue-200">
      <img className="w-full max-w-full" src={graphic} alt=""/>
    </section>
    <section className="py-20">
      <div className="container mx-auto max-w-screen-lg px-5 text-center flex flex-col justify-items-end">
        <h1 className="text-4xl font-heading font-bold text-gray-800 mb-2">How it works</h1>
        <p className="max-w-screen-md mx-auto block leading-7">Flickity powered websites take advantage of modular design principles. You can choose from our robust set of components that have been specifically designed for your unique needs. We configure the site to your needs, manage and update your content and handle all the infrastructure to do so. All of this is fully-managed for you so you can focus on what you do best.</p>
      </div>
    </section>
    <section className="py-20 bg-gray-800 text-white">
      <div className="container mx-auto max-w-screen-lg px-5 text-center flex flex-col justify-items-end">
        <h1 className="text-4xl font-heading font-bold mb-8">See it live</h1>
        <div className="flex flex-col md:flex-row">
          <div className="mb-8 md:mb-0 md:mr-4 flex-1 relative">
            <a href="#0" className="shadow-lg block h-64" style={{ backgroundImage: `url(${demo1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            </a>
            <DemoButton 
              href="https://demo-1-flickity.netlify.app/"
              target="_blank"
              className="absolute px-6 py-2 inline-block rounded-lg mt-4 bg-gray-700 hover:bg-gray-800 duration-200"
            >
              View Live Demo
            </DemoButton>
          </div>
          <div className="md:ml-4 flex-1 relative">
            <a href="#0" className="shadow-lg block h-64" style={{ backgroundImage: `url(${demo2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}></a>
            <DemoButton 
              href="https://demo-2-flickity.netlify.app/"
              target="_blank"
              className="absolute px-6 py-2 inline-block rounded-lg mt-4 bg-gray-700 hover:bg-gray-800 duration-200" 
            >
              View Live Demo
            </DemoButton>
          </div>
        </div>
      </div>
    </section>
    <section className="py-20">
      <div className="container mx-auto max-w-screen-lg px-5">
        <h1 className="text-4xl font-heading font-bold text-gray-800 mb-8 text-center">Pricing</h1>
        <div className="flex flex-col md:flex-row">
          <div className="bg-white rounded border p-5 flex-1 mb-4 md:mb-0 md:mr-4 shadow">
            <h2 className="text-2xl font-heading text-gray-900 flex">Basic <span className="text-green-600 ml-auto font-body font-bold">$1499</span></h2>
            <span className="font-bold my-4 inline-block">Includes:</span>
            <ul className="list-disc list-inside">
              <li className="mb-3">Initial site build</li>
              <li className="mb-3">Content managment and updates</li>
              <li className="mb-3">Fast reliable hosting (includes SSL, just provide your domain)</li>
              <li><span className="text-green-600">$100/mo</span> - Covers updates and hosting.</li>
            </ul>
          </div>
          <div className="bg-white rounded border p-5 flex-1 md:ml-4 shadow">
            <h2 className="text-2xl font-heading text-gray-900 flex">Custom <span className="text-green-600 ml-auto font-body font-bold">Let's talk</span></h2>
            <span className="font-bold my-4 inline-block">Includes:</span>
            <ul className="list-disc list-inside">
              <li className="mb-3">Everything from Basic tier</li>
              <li className="mb-3">Custom designed components</li>
              <li className="mb-3">e-commerce (rentals, art downloads, merch, etc)</li>
              <li>Content managment system (empowers you to make your own content updates)</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="py-20 bg-gray-300">
      <div className="container mx-auto max-w-screen-lg px-5 text-center">
        <h1 className="text-4xl font-heading font-bold text-gray-800 mb-2">Join The Community</h1>
        <p className="max-w-screen-md mx-auto block leading-7 mb-8">Join other indie fimmakers and help out with their growth + marketing. One click of a button could help a filmmaker get that breakthrough they needed.</p>
        <a className="px-8 py-4 inline-block rounded-lg mt-4 text-white font-bold text-2xl mx-auto bg-green-700 hover:bg-green-800 duration-200" targer="_blank" href="https://app.slack.com/client/T01E33HHRED/D01DQEDR3T4">Join Now!</a>
      </div>
    </section>
  </Layout>
)

export default IndexPage
